import { SaveOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { CreateOrganizationDocument, OrganizationStatusEnum } from '@salescore/admin-client-api'
import { Button, Form, Input, message, Select } from 'antd'
import { t } from 'i18next'

import { ORGANIZATION_STATUS_OPTIONS, OrganizationStatusTag } from '../shared/OrganizationStatusTag'

interface FormValue {
  name: string
  status: OrganizationStatusEnum
  adminMemo: string | undefined
}

export const CreateOrganizationForm = ({ onAfterFinish }: { onAfterFinish: () => void }): JSX.Element => {
  const [createOrganizationMutation, { loading }] = useMutation(CreateOrganizationDocument)

  const onFinish = (value: FormValue): void => {
    void createOrganizationMutation({
      variables: {
        organization: {
          name: value.name,
          status: value.status,
          adminMemo: value.adminMemo ?? '',
        },
      },
      onCompleted: () => {
        void message.success(t(`更新しました`))
        onAfterFinish()
      },
    })
  }

  return (
    <Form onFinish={onFinish} initialValues={{ status: OrganizationStatusEnum.Using }} style={{ width: 800 }}>
      <Form.Item label="組織名" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="ステータス" name="status" rules={[{ required: true }]}>
        <Select bordered={false} style={{ width: 110 }}>
          {ORGANIZATION_STATUS_OPTIONS.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              <OrganizationStatusTag status={option.value} />
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="内部メモ(任意)" name="adminMemo">
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={loading}>
          保存
        </Button>
      </Form.Item>
    </Form>
  )
}
